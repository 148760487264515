import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { Button } from "../Button";
import { InputList } from "../InputList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { InputDate } from "../InputDate";

export const ModalDocumentos = ({
  type,
  title,
  document,
  labelConfirm,
  lists,
  documentsListName,
  claimable = false,
  confirm,
  cancel,
}) => {
  const [revision, setRevision] = useState(
    document?.revision === "NO OK" ? false : true
  );
  const [listsProps, setListsProps] = useState({});
  const [filename, setFilename] = useState("");
  const [data64File, setData64File] = useState("");
  const [fileExt, setFileExt] = useState("");
  const [fechaEmision, setFechaEmision] = useState(document?.fechaEmision ?? undefined);
  const [fechaPrevision, setFechaPrevision] = useState(document?.fechaPrevision ?? undefined);

  const initLists = useCallback(() => {
    const tmpListsProps = {};
    Object.entries(lists).forEach(([listName, listProps]) => {
      tmpListsProps[listName] = {
        value: listProps.valorDefecto,
        options: convertOptions(listProps.valores),
      };
    });

    if (type === "editActiveDocument") {
      const idEntidadRelacional = lists.TipoDocumentoActivo.valores.find(
        (doc) => doc.etiqueta === document.tipo
      )?.valor;

      const listaRelacional = {
        nombreLista: "TipoDocumentoActivo",
        idEntidadRelacional,
      };

      const data = {
        lista: "TipoReclamacion",
        listaRelacional,
      };
      API.obtenerLista(data).then((res) => {
        tmpListsProps.TipoReclamacion.options = convertOptions(res.valores);
        setListsProps(tmpListsProps);
      });
    } else setListsProps(tmpListsProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lists]);
  useEffect(() => initLists(), [initLists]);

  const handleListChange = (field, value) => {
    const tmpListsProps = { ...listsProps };
    tmpListsProps[field.list].value = value;

    if (lists[field.list].listasHijas.length === 0)
      setListsProps(tmpListsProps);
    else
      lists[field.list].listasHijas.forEach((list) => {
        const listaRelacional = {
          nombreLista: field.list,
          idEntidadRelacional: value,
        };
        const data = {
          lista: list,
          listaRelacional,
        };
        API.obtenerLista(data).then((res) => {
          tmpListsProps[list].options = convertOptions(res.valores);
          setListsProps(tmpListsProps);
        });
      });
  };

  const convertOptions = (options) =>
    options.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

  const convertBase64 = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const base64 = reader.result.toString().replace(/^data:(.*,)?/, "");
        const nameSplit = file.name.split(".");
        setFilename(file.name);
        setData64File(base64);
        setFileExt(nameSplit[nameSplit.length - 1]);
      };
    } else {
      setFilename("");
      setData64File("");
      setFileExt("");
    }
  };

  const handleConfirm = () => {
    const data = {};
    if (type === "addDocument")
      data.tipoDocumento = listsProps[documentsListName]?.value;
    if (claimable) {
      data.esRevisado = revision;
      data.tipoReclamacion = listsProps.TipoReclamacion?.value;
      data.tipoIncidencia = listsProps.TipoIncidencia?.value;
    }
    data.contenidobase64 = data64File;
    data.tituloDocumento = filename;
    data.tipoContenido = fileExt;
    data.fechaEmision = fechaEmision;
    data.fechaPrevision = fechaPrevision;
    
    confirm(data);
  };

  const disabledConfirm = () => {
    if (type === "addDocument" && (!listsProps[documentsListName]?.value) )
      return true;

    if (claimable) {
      if (revision) {
        if (!document?.nombreDocumento && !filename ) return true;
      } else if (
        !listsProps.TipoReclamacion?.value ||
        !listsProps.TipoIncidencia?.value
      )
        return true;
    } else if (!filename || !fechaPrevision ) return true;

    return false;
  };

  const formatoFecha  = (fecha) => {
    if(fecha)
      return fecha.split("T")[0]
    else return fecha;
  }

  return (
    <S.Wrapper onClick={cancel}>
      <S.Content onClick={(event) => event.stopPropagation()}>
        <S.Header>
          <p>{title}</p>
        </S.Header>
        <S.Body>
          <S.WrapperModalBody columns={claimable ? 2 : 1}>
            <div>
              {type === "addDocument" ? (
                <InputList
                  field={{
                    label: "Tipo Documento",
                    name: "tipoDocumento",
                    list: documentsListName,
                  }}
                  value={listsProps[documentsListName]?.value || ""}
                  options={listsProps[documentsListName]?.options || []}
                  handleChange={handleListChange}
                  isClearable={lists[documentsListName]?.mostrarVacio || false}
                />
              ) : (
                <>
                  <p>Tipo Documento</p>
                  <select disabled={true}>
                    <option>{document?.tipo}</option>
                  </select>
                </>
              )}
            </div>

            {claimable && (
              <>
                <div>
                  <p>Resultado revisión</p>
                  <input
                    type="checkbox"
                    checked={revision}
                    onChange={(e) => setRevision(e.target.checked)}
                  />
                </div>

                {!revision && (
                  <>
                    <InputList
                      field={{
                        label: "Tipo Reclamación",
                        name: "tipoReclamacion",
                        list: "TipoReclamacion",
                      }}
                      value={listsProps.TipoReclamacion?.value || ""}
                      options={listsProps.TipoReclamacion?.options || []}
                      handleChange={handleListChange}
                      isClearable={lists.TipoReclamacion?.mostrarVacio || false}
                    />
                    <InputList
                      field={{
                        label: "Motivo",
                        name: "tipoIncidencia",
                        list: "TipoIncidencia",
                      }}
                      value={listsProps.TipoIncidencia?.value || ""}
                      options={listsProps.TipoIncidencia?.options || []}
                      handleChange={handleListChange}
                      isClearable={lists.TipoIncidencia?.mostrarVacio || false}
                    />
                  </>
                )}
              </>
            )}
             <InputDate
                  key={"fechaEmision"}
                  field={{ name: "fechaEmision", label: "Fecha de emisión", type:"date"}}
                  value={formatoFecha(fechaEmision)}
                  handleChange={(_f,v)=>setFechaEmision(v)}
                  />
              <InputDate
                  key={"fechaPrevision"}
                  field={{ name: "fechaPrevision", label: "Fecha previsión", type:"date"}}
                  value={formatoFecha(fechaPrevision)}
                  handleChange={(_f,v)=>setFechaPrevision(v)}
                  />
            <S.InputFile>        
              <input type="file" onChange={convertBase64} />
              <FontAwesomeIcon icon={faFileArrowUp} size="2x" />
              <p>
                {filename ||
                  document?.nombreDocumento ||
                  "Ningún archivo seleccionado"}
              </p>
            </S.InputFile>
          </S.WrapperModalBody>

          <S.Buttons>
            <Button
              label={labelConfirm}
              onClick={handleConfirm}
              disabled={disabledConfirm()}
            />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </S.Buttons>
        </S.Body>
      </S.Content>
    </S.Wrapper>
  );
};

ModalDocumentos.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  document: PropTypes.object,
  labelConfirm: PropTypes.string,
  lists: PropTypes.object,
  documentsListName: PropTypes.string,
  activo: PropTypes.object,
  claimable: PropTypes.bool,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
};
